@import "@assets/styles/mixins.scss";
.search-container {
  width: 100%;
}
// .filter-type_container{
//     position: relative;
//     top:3px
// }
.refund-container {
  width: 100%;
  position: relative;
  bottom: 30px;

  .timeInput-box-select {
    font-size: 12px;
    color: #707070;
    min-width: 150px;
    margin-left: 20px;
  }

  .MuiSvgIcon-root.MuiTableSortLabel-icon {
    margin: 0;
  }

  .MuiSvgIcon-root.MuiTableSortLabel-icon {
    margin: 0;
  }

  // No.
  @include tableColumnHeadWidthByIndex(1, 10px) {
    pointer-events: none;
    width: 20px;
  }
  //Order ID
  @include tableColumnHeadWidthByIndex(2, 70px) {
    pointer-events: none;
  }
  // Order No
  @include tableColumnHeadWidthByIndex(3, 120px) {
    padding: 0px;
  }
  // Order Date
  @include tableColumnHeadWidthByIndex(4, 80px) {
    padding: 0px;
  }
  // Refund Date
  @include tableColumnHeadWidthByIndex(5, 85px) {
    // pointer-events: none;
    padding: 0px;
  }
  //Original order id
  @include tableColumnHeadWidthByIndex(6, 70px) {
    pointer-events: none;
  }
  // Customer
  @include tableColumnHeadWidthByIndex(7, 100px) {
    padding: 0px;
  }
  // Tacking No
  @include tableColumnHeadWidthByIndex(8, 60px) {
    // padding-left: 0px;
  }
  //Discount type
  @include tableColumnHeadWidthByIndex(9, 70px) {
    pointer-events: none;
  }
  //Flyer Qty
  @include tableColumnHeadWidthByIndex(10, 70px) {
    pointer-events: none;
  }
  //Parcel Amt
  @include tableColumnHeadWidthByIndex(11, 70px) {
    pointer-events: none;
  }
  //Discount
  @include tableColumnHeadWidthByIndex(12, 70px) {
    pointer-events: none;
  }
  //Net total
  @include tableColumnHeadWidthByIndex(13, 70px) {
    pointer-events: none;
  }
  // Amount
  @include tableColumnHeadWidthByIndex(14, 80px) {
    padding-left: 0px;
  }

  // Status
  @include tableColumnHeadWidthByIndex(15, 60px) {
    padding-left: 0px;
  }
  //EGHL no
  @include tableColumnHeadWidthByIndex(16, 70px) {
    pointer-events: none;
  }

  @include tableColumnBodyWidthByIndex(2, 0px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(3, 10px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(4, 0px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(5, 10px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(6, 10px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(7, 0px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(8, 0px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(9, 0px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(10, 0px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(11, 0px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(12, 10px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(13, 0px, 0px, 0px);
  @include tableColumnBodyWidthByIndex(15, 0px, 0px, 0px);

  .search-container_filter {
    display: inline-block;
    align-items: center;
    position: relative;
    top: 2px;
    .filter-type {
      margin-top: 2px;
      min-width: 100px;
    }
    #demo-simple-select-helper-label {
      position: relative;
      // bottom: 20px;
      font-family: din;
    }
    .date-picker {
      padding-top: 5px;
      position: relative;
      // position: relative;
      // top: -7px;
      // right: 9px;
      // width: 370px;
    }
    .filter-type {
      margin-right: 5px;
    }
    .filter-type_container {
      display: inline-block;
    }
    .search-button-container {
      margin: 4px 0px 0px 0px;
      display: flex;
      position: relative;
      left: 20px;

      @media (max-width: 883px) {
        left: 0px;
      }

      .clearButton {
        color: #09a74c;
        padding: 5px;
        top: -2px;
      }

      .downloadButton {
        color: #1e91cf;
        padding: 5px;
        top: -2px;
      }
    }

    .MuiGrid-justify-xs-center {
      @media (max-width: 883px) {
        justify-content: flex-start;
      }
    }
  }
}
