@import "@assets/styles/mixins.scss";

.customer-container{
    width: 100%;
    position: relative;
    bottom: 30px;
    
    // No.
    @include tableColumnHeadWidthByIndex(1, 20px) { pointer-events: none; }
    
    // View
   
    @include tableColumnBodyWidthByIndex(1, 0px, none, 1px);

    @include tableColumnHeadWidthByIndex(2, 100px);

    @include tableColumnHeadWidthByIndex(3, 100px);

    @include tableColumnHeadWidthByIndex(5, 70px);

    @include tableColumnHeadWidthByIndex(7, 100px);

    @include tableColumnHeadWidthByIndex(8, 100px) ;

    @include tableColumnHeadWidthByIndex(9, 20px) { pointer-events: none; }

    @include tableColumnHeadWidthByIndex(10, 0px) ;

    @include tableColumnHeadWidthByIndex(12, 20px) { pointer-events: none; }

    @include tableColumnHeadWidthByIndex(13, 20px) { pointer-events: none; }
}

.search-button-container {
    .import-csv-member-btn {
        border-radius: 23px;
        padding-left: 16px;
        padding-right: 16px;
        width:  380px
    };

    .import-csv-member-btn:hover {
        background-color: rgb(237, 170, 52);
        opacity: 0.8;
    }
}