@import "@assets/styles/mixins.scss";

.flyer-bag-history-container {
    width: 100%;
    
    @include tableColumnHeadWidthByIndex(1, 80px); 
  
    @include tableColumnHeadWidthByIndex(2, 80px);
  
    @include tableColumnHeadWidthByIndex(9, 160px);
  
    // @include tableColumnHeadWidthByIndex(16, 300px);
  }
  .search-button-container {
    .more-filter-btn {
      border-radius: 23px;
    }
  }