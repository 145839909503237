@import '@assets/styles/variable.scss';
@import '@assets/styles/mixins.scss';
@font-face {
  src: url(DINAlternateMedium.otf);
  font-family: dinalternate;
}

.customtable-container {
  .custom-table {
    max-height: 550px;
    .custom-table_head {
      margin-top: 10px;
    }
  }

  .button-container {
    padding: 0px 5px 3px 5px;

    .customtable-load-more-btn {
      margin: 0 auto;
      display: block;
    }
  }
  .MuiTableCell-root {
    padding-top: initial;
    padding-bottom: initial;
    font-family: dinalternate;
    font-size: 12px;
  }
  .customtable-container_search {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 20px 5px;
    justify-content: space-between;
    .search-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include for-max-phone-layout {
        flex-direction: column;
        align-items: flex-start;
      }
      .search-container_search-box {
        width: 210px;
        margin-right: 20px;
        position: relative;
        top: 7px;
        ::placeholder {
          color: #a2a2a2;
          font-family: 'din';
        }
        @include for-max-phone-layout {
          width: 150px;
        }
        @include for-medium-desktop-layout {
          width: 100px;
        }
      }
      .search-container_filter {
        display: flex;
        flex-direction: row;
      }
    }
  }
  .customtable-container_popup-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 20px 5px;
    justify-content: space-between;
    background-color: white;
    .search-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include for-max-phone-layout {
        flex-direction: column;
        align-items: flex-start;
      }
      .search-container_search-box {
        width: 210px;
        margin-right: 20px;
        position: relative;
        top: 7px;
        ::placeholder {
          color: #a2a2a2;
          font-family: 'din';
        }
        @include for-max-phone-layout {
          width: 150px;
        }
        @include for-medium-desktop-layout {
          width: 100px;
        }
      }
      .search-container_filter {
        display: flex;
        flex-direction: row;
      }
    }
  }
  svg.status-ok {
    color: rgb(18, 206, 125);
    font-size: 20px;
  }

  svg.mui-icon {
    color: #1e91cf;
    // padding: 0px 5px;
    font-size: 20px;
    cursor: pointer;
  }

  svg.status-no {
    font-size: 20px;
    color: rgb(193, 193, 193);
  }

  .pagination {
    .MuiTablePagination-actions {
      color: rgba(0, 0, 0, 0.26);
      pointer-events: none;
    }
  }

  .search-container-more-filter-button {
    margin-top: 25px;
  }
}
