@import '@assets/styles/variable.scss';
@import '@assets/styles/mixins.scss';
// @import 'font-awesome/css/font-awesome.min.css';
@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
.password-requirement{
  font-size: 13px;
  border: 1px rgb(166, 213, 250) solid;
  background-color: rgb(217, 238, 255);
  ul li{
    text-align: left;
  }
  
}
.login_container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  .login_title_container {
    background-color: $color-background;
    padding-top: 2rem;
    padding-bottom: 6rem;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
    justify-content: center;
    .login_title {
      text-align: center;
      margin-bottom: 6rem;
      justify-content: center;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      max-width: 50%;

      .login_text {
        color: rgb(39, 157, 235);
      }
    }
  }
  .login_form {
    margin-top: -10rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    text-align: center;
    .login-form-content {
      margin-top: 3rem;
      padding-left: 40px;
      padding-right: 40px;
    }
    .change-form-content {
      margin-top: 2rem;
      padding-left: 40px;
      padding-right: 40px;
    }
    .login_paper {
      margin-top: 20px;
      height: 40px;
      text-align: left;
      display: flex;
      align-items: center;
    }

    .MuiSvgIcon-root {
      color: rgb(143, 130, 130);
      padding-left: 10px;
      size: 20px;
      font-size: 32px;
      // max-width: 10px;
    }
    .sign_in_icon {
      color: black;
      padding-left: 10px;
      padding-right: 20px;
      font-size: 20px;
      max-width: 10px;
    }
    .login_button_container {
      display: flex;
      justify-content: flex-end;
      margin-right: 40px;
      padding-top: 1rem;
      .login_button {
        background-color: #2979ff;
        color: $color-txt-primary;
      }
    }
    .login_forget_password {
      color: $color-txt-secondary;
      font-size: 14px;
      text-align: left;
      cursor: pointer;
      text-decoration: underline;
    }
    .login_store_password {
      text-align: center;
      cursor: pointer;
      text-decoration: underline;
    }
    .login_input {
      width: 100%;
      padding-left: 10px;
    }
    .login_input_password {
      font-family: 'password';
      -webkit-text-security: disc;
      -moz-text-security: disc;
      -ms-text-security: disc;
      -o-text-security: disc;
      width: 100%;
      padding-left: 10px;
    }
    .login-alert-body {
      width: 100%;
      margin-bottom: 10px;
      color: $color-txt-third;
      font-size: 13px;
      text-align: center;
    }
    .login-alert-box {
      padding: 10px;
      border-style: solid;
      border-radius: 2px;
      border-width: 1px;
      border-color: $color-txt-third;
      background-color: $color-container-secondary;
    }
    .login-success-box {
      padding: 10px;
      border-style: solid;
      border-radius: 2px;
      border-width: 1px;
      border-color: $color-txt-fourth;
      color: white;
      background-color: #4caf50;
    }
    .login_form_card {
      width: 28%;
      &.mfa {
        width: 40%;
      }
      @include for-desktop {
        width: 100%;
        margin-left: 20%;
        margin-right: 20%;
      }
      @include for-phone-only {
        width: 100%;
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}
