@import "@assets/styles/mixins.scss";
.order-container {
	width: 100%;
	position: relative;
	bottom: 25px;

	.paddingLeft {
		padding-left: 0px !important;
	}

	.timeInput-box-select {
		font-size: 12px;
		color: #707070;
		min-width: 150px;
		margin-left: 20px;
	}
	.MuiSvgIcon-root.MuiTableSortLabel-icon {
		margin: 0;
	}

	// No.
	@include tableColumnHeadWidthByIndex(1, 20px) {
		pointer-events: none;
		width: 10px;
	}

	// orderTxnId
	@include tableColumnHeadWidthByIndex(2, 80px) {
		padding: 10px;
	}
	// Order number
	@include tableColumnHeadWidthByIndex(3, 100px) {
		padding: 16px;
	}

	// Txn No
	@include tableColumnHeadWidthByIndex(4, 70px) {
		padding: 10px;
	}

	// Tracking Number
	@include tableColumnHeadWidthByIndex(5, 80px) {
		padding: 10px;
	}

	// Original Order Number
	@include tableColumnHeadWidthByIndex(6, 80px) {
		padding: 10px;
	}

	// Order Datetime
	@include tableColumnHeadWidthByIndex(7, 80px) {
		padding: 10px;
	}

	// Drop-off Datetime
	@include tableColumnHeadWidthByIndex(8, 80px) {
		padding: 10px;
	}

	// Collection Datetime
	@include tableColumnHeadWidthByIndex(9, 80px) {
		padding-left: 10px;
	}

	// Drop-off Store Code
	@include tableColumnHeadWidthByIndex(10, 80px) {
		padding-left: 10px;
	}

	// Collection Store Code
	@include tableColumnHeadWidthByIndex(11, 50px) {
		padding-left: 10px;
	}

	// Sender Account Number
	@include tableColumnHeadWidthByIndex(12, 50px) {
		padding-left: 10px;
	}
	// Sender Mobile Number
	@include tableColumnHeadWidthByIndex(13, 80px) {
		padding-left: 10px;
	}

	// Receiver Name
	@include tableColumnHeadWidthByIndex(14, 60px) {
		padding-left: 10px;
	}

	// Receiver Mobile Number
	@include tableColumnHeadWidthByIndex(15, 80px) {
		padding-left: 10px;
	}

	// Discount Type
	@include tableColumnHeadWidthByIndex(16, 60px) {
		pointer-events: none;
		padding-left: 10px;
	}

	//Parcel Amount
	@include tableColumnHeadWidthByIndex(17, 60px) {
		pointer-events: none;
		padding-left: 10px;
	}
	// Add Ons Amount
	@include tableColumnHeadWidthByIndex(18, 80px) {
		pointer-events: none;
		padding-left: 10px;
	}
	// Discount
	@include tableColumnHeadWidthByIndex(19, 60px) {
		pointer-events: none;
		padding-left: 10px;
	}
	// Net total
	@include tableColumnHeadWidthByIndex(20, 60px) {
		padding-left: 10px;
		pointer-events: none;
	}
	// Refund Amount
	@include tableColumnHeadWidthByIndex(21, 60px) {
		pointer-events: none;
		padding-left: 10px;
	}
	// orderType
	@include tableColumnHeadWidthByIndex(22, 60px) {
		pointer-events: none;
		padding-left: 10px;
	}
	// paymentMethod
	@include tableColumnHeadWidthByIndex(23, 60px) {
		pointer-events: none;
		padding-left: 10px;
	}
	// Status
	@include tableColumnHeadWidthByIndex(24, 60px) {
		pointer-events: none;
		padding-left: 10px;
	}

	// delivery fee
	@include tableColumnHeadWidthByIndex(25, 60px) {
		padding-left: 10px;
	}

	// grandtotal
	@include tableColumnHeadWidthByIndex(26, 70px) {
		pointer-events: none;
		padding-left: 10px;
	}

	@include tableColumnBodyWidthByIndex(1, 0px, none, 0px);

	@include tableColumnBodyWidthByIndex(2, 0px, 10px, 0px);

	@for $i from 3 through 26 {
		@include tableColumnBodyWidthByIndex($i, 10px, 10px, 0px);
	}

	// @for $i from 9 through 20 {
	// 	@include tableColumnBodyWidthByIndex($i, 30px, none, 0px);
	// }
	// @include tableColumnBodyWidthByIndex(10, 30px, none, 0px);
	// @for $i from 11 through 18 {
	// 	@include tableColumnBodyWidthByIndex($i, 0px, 0px, 0px);
	// }
}

.customtable-container {
	width: 100%;
	.customtable-container_search {
		.search-container {
			align-items: initial;
		}
	}
}

.search-container_filter {
	display: flex;
	align-items: center;
	width: 100%;
	position: relative;
	top: 1px;
	.search-button-container {
		display: flex;
		position: relative;
		align-items: center;
		top: 10px;
		right: -70px;
		margin-top: 0;

		@media (max-width: 883px) {
			left: 0px;
		}
	}

	.date-picker {
		position: relative;
		top: -7px;
	}

	.MuiGrid-justify-xs-center {
		@media (max-width: 883px) {
			justify-content: flex-start;
		}
	}
}

.listCategory-container {
	display: flex;
	flex-direction: column;
	width: 100%;

	.listCategory-container_button {
		margin-bottom: 20px;
		margin-right: 20px;
		float: right;
	}

	.listCategory-container_box {
		display: flex;
		cursor: pointer;
		margin: 0px 20px 20px 0px;
		border: 1px solid #f1f7ff;
		border-radius: 6px;
		padding: 10px;
		flex-direction: column;
		font-size: 8pt;

		&:hover {
			border: 1px solid #174b9d;
		}
		.category-image {
			height: 120px;
			object-fit: contain;
			width: 100%;
		}
		.banner-title {
			margin-top: 5px;
			font-size: 11pt;
			font-weight: 700;
			color: #174b9d;
		}
		.banner-description {
			margin-top: 5px;
			font-size: 8pt;
			font-weight: 700;
			// color: #174b9d;
		}
		.banner-start {
			font-size: 8pt;
			display: inline-block;
		}
		.banner-end {
			font-size: 8pt;
		}
		.banner-button {
			margin: auto;
			margin-top: 20px;
		}
	}
}
