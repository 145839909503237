$color-background: #e9ebee;

$color-theme-primary:#de2729;
$color-theme-secondary:#679ee6;
$color-theme-third:#f52e31;
$color-theme-fourth:#92282a;
$color-theme-fifth:#f36d45;
$color-theme-six:#b9b8b9;
$color-theme-seven:#cecbce;
$color-theme-eight:#407ecf;



$color-container-primary: #fff;
$color-container-secondary: #fff1f0;
$color-container-third: #b9b8b9;

$color-txt-default: #000000;
$color-txt-primary: #fff;
$color-txt-secondary: #b9b8b9;
$color-txt-third: #de2729;
$color-txt-fourth:#B7EB8E;
$color-txt-fifth:#f3a4b5;

$color-link-1:#5e72e4;
$color-link-2:#233dd2;

$color-icon-1: #b9b8b9;
$color-icon-2: #5e72e4;
$color-icon-3: #ffd600;
$color-icon-4: #f3a4b5;
$color-icon-5: #a141b9;
$color-icon-6: #b6b4b4;
$color-icon-7: #f5365c;
$color-icon-8: #3d2c30;

$color-table-1:#0d0d0d;
$color-table-2:#f6f9fc;
$color-table-3:#b9b8b9;
$color-table-4:#afecd2;
$color-table-warning:#fed3ca;
$color-table-warning-hover:#fed3ca;

$color-border-1:#FF0000;
$color-border-2:#B7EB8E;
$color-border-3:#dee2e6;
$color-border-4:#0d0d0d;
$color-border-5: #cad1d7;

$warning-color: #FF0000;
$success-color: #B7EB8E;
$background-success-color: #417A15;
$background-warning-color: #910707;

$hr: #FF3838;

$color-box-shadow:#0d0d0d;