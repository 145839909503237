.paper-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 16px;
  position: relative;
  bottom: 15px;

  .paper-container_box {
    font-size: 16px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .paper-container_box-edit {
      font-weight: initial;
      font-size: 7pt;
    }
    .paper-container_box-button {
      cursor: pointer;
      align-items: center;
      display: flex;
      border-radius: 10px;
      padding: 5px;
      color: #1e91cf;
      font-size: 18px;
      &:hover {
        background-color: #f4f4f4;
      }
      // .button-add {
      //   color: #000;
      //   padding-right: 7.5px;
      //   font-size: 18.5px;
      // }
      img {
        padding-right: 12px;
        // padding-bottom: 5px;
      }
    }

    .paper-container_box-button_disabled {
      display: none !important;
      cursor: cursor;
      align-items: center;
      display: flex;
      border-radius: 10px;
      padding: 5px;
      color: rgba(0, 0, 0, 0.54);
      .button-add {
        color: rgba(0, 0, 0, 0.54);
        padding-left: 7.5px;
        font-size: 18.5px;
      }
    }
  }
}
.paper-container-popUp {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  position: relative;
  bottom: 15px;
  overflow-y: auto;
  .paper-container_box {
    font-size: 16px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .paper-container_box-edit {
      font-weight: initial;
      font-size: 7pt;
    }
    .paper-container_box-button {
      cursor: pointer;
      align-items: center;
      display: flex;
      border-radius: 10px;
      padding: 5px;
      color: #1e91cf;
      font-size: 18px;
      &:hover {
        background-color: #f4f4f4;
      }
      // .button-add {
      //   color: #000;
      //   padding-right: 7.5px;
      //   font-size: 18.5px;
      // }
      img {
        padding-right: 12px;
        // padding-bottom: 5px;
      }
    }

    .paper-container_box-button_disabled {
      display: none !important;
      cursor: cursor;
      align-items: center;
      display: flex;
      border-radius: 10px;
      padding: 5px;
      color: rgba(0, 0, 0, 0.54);
      .button-add {
        color: rgba(0, 0, 0, 0.54);
        padding-left: 7.5px;
        font-size: 18.5px;
      }
    }
  }
}

.paper-container_save-button{
  cursor: pointer;
  color: #fff;
  font-family: din;
  font-size: 12.5px;
  background: #1E91CF 0% 0% no-repeat padding-box;
  padding: 6.5px 20px;
  border-radius: 23px;
  line-height: initial;
  &:hover {
    background-color: rgb(237, 170, 52);
    color: #fff;
    opacity: 0.8;
  }
}
