@font-face {
  src: url(DINMediumRegular.ttf);
  font-family: din;
}

body {
  margin: 0;
  background-color: #f5f6f9;
  font-family: din;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.selections-modal .landing-container {
  width: 80%;
  height: 80%;
  position: absolute;
  overflow: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
