@import '@assets/styles/variable.scss';
@import '@assets/styles/mixins.scss';

.custom-drawer {
  .MuiDrawer-paperAnchorRight {
    width: 350px;
  }
  .MuiBackdrop-root {
    background-color: transparent;
  }

  .MuiAccordion-root:before {
    background-color: rgb(255, 255, 255);
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root {
    min-height: 34px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .PrivateSwitchBase-root-10 {
    padding: 5px 0px 8px 0px;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    border-radius: 5px;
    &[aria-expanded='true']:focus {
      // outline: 2px solid rgb(107, 176, 255);
      box-shadow: 0 0 5px rgb(49, 128, 235);
    }
  }

  .flex-row {
    display: flex;
    flex-direction: column;
  }

  .txtbox-filter {
    width: 280px;
  }
  .dropdown-filter {
    margin-top: 5px;
    width: 280px;
    height: 30px;
  }

  .drawer-content {
    min-width: 350px;
    overflow: hidden;

    .drawer-header {
      display: flex;
      flex-direction: row;
      padding: 5px 5px 5px 15px;
      margin-top: 10px;

      .title {
        flex-grow: 1;
        padding: 20px;
        font-size: 18px;
      }
    }

    .drawer-filters {
      padding: 15px 10px;
      max-height: calc(100vh - 140px);
      height: calc(100vh - 140px);
      overflow-y: scroll;

      .sub-component-header {
        display: block;
        font-weight: bold;
      }

      .btn-clear {
        width: 40px;
        padding: 10px 0px;
        color: gray;
        cursor: pointer;
      }

      .filled {
        color: rgb(49, 128, 235) !important;
      }

      .btn-clear:hover {
        text-decoration: underline;
      }

      .checkbox-group-container {
        display: flex;
        flex-direction: column;

        .checkbox-container {
          display: block;
          // cursor: pointer;

          .checkbox-label {
            padding: 0 10px;
          }
        }
      }

      .radio-group-container {
        display: flex;
        flex-direction: column;

        .radio-label {
          padding: 0 10px;
        }
      }

      .datepicker-container {
        .datepicker-label-title {
          display: inline-block;
          width: 60px;
          text-align: right;
          padding-right: 10px;
        }
      }
    }

    .drawer-footer {
      display: inline-block;
      flex-direction: row;
      text-align: center;
      margin-top: -100px;
      width: 350px;
      height: 100px;

      .right-button {
        // width: 100px;
        font-size: 12px;
      }
      .left-button {
        font-size: 12px;
      }
    }
  }
}
