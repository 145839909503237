@import "@assets/styles/variable.scss";
@import "@assets/styles/mixins.scss";

.layout-container {
  display: flex;
}

.layout-left-panel {
  display: flex;
  z-index: 100;
  width: 190px;
  background-color: #F1F7FF;
  opacity: 1.4;
  height: 100vh;
  position: fixed;
  z-index: 101;

  @include for-max-phone-layout {
    display: none;
  }
}

.cancel{
  margin-right:80px;
  max-width: 70px;
  .MuiButton-root{
    line-height: 1;
  }
}

.layout-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  // position: relative;
  // bottom: 45px;;
  .layout-content_drawer {
    div.MuiDrawer-paperAnchorLeft {
      width: 50%;
    }
  }
 
  .apptop-bar {
    padding: 5px 30px ;
    display: flex;
    border-bottom: 2px solid #f4f8fc;
    max-height: 30px;
    flex-direction: row;
    justify-content: space-between;
    // position: relative;
    // top: 50px;
    // z-index: 100;

    .apptop-bar_account {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13.5px;
      color: #707070;

      // svg.MuiSvgIcon-root {
      //   font-size: 25px;
      //   color: #707070;
      // }
    }
    
    .apptop-bar_signout-btn {
      margin-left: 45px;
      margin-right: 25px;
      margin-top: 10px;
    }
  }

  .layout-content_description {
    border-bottom: 2px solid #f4f8fc;
    position: relative;
    top : 10px;
      .layout-content-title {
        font-size: 15pt;
        font-weight: 600;
        text-transform: uppercase;
        float: left;
        margin-top: 27px;
      }

      .layout-content-description-text {
        float: left;
        margin-left: 20px;
        margin-top: 30px;
      }

      .layout-breadcrumbs {
        margin-bottom: 15px;
        margin-left: 210px;
        align-items: center;
        font-size: 9pt;
        color: #555;
        display: flex;
        @include for-max-phone-layout {
          margin-left: 10px;
        }
        .layout-content-desc_btn {
          display: flex;
          flex-direction: row;
        }
        .layout-breadcrumbs__item {
          float: left;
        }
        a {
          text-decoration: none;
        }
        span {
          margin-right: 7px;
        }
      }

      .layout-content-desc {
        position: relative;
        top: -30px;
        z-index: 1;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 210px;
        align-items: center;
        font-size: 9pt;
        color: #555;
        @include for-max-phone-layout {
          margin-left: 10px;
        }
        .layout-content-desc_btn {
          display: flex;
          flex-direction: row;
          position: relative;
          top: 10px;
          .store-details{
            margin-left: 50px;
            .MuiButton-root{
              background: #2E96FF1A 0% 0% no-repeat padding-box;
              border: 1px solid #1E91CF;
              border-radius: 10px;
              font-size: 12px;
              color: #1E91CF;
              padding: 10px;
              width: 135px;
            }
          }
          .store-products{
            margin-left: 10px;
            .MuiButton-root{
              background: #2E96FF1A 0% 0% no-repeat padding-box;
              border: 1px solid #1E91CF;
              border-radius: 10px;
              font-size: 12px;
              color: #1E91CF;
              padding: 10px;
              width: 135px;
            }
          }
          .store-delivery{
            margin-left: 10px;
            .MuiButton-root{
              background: #2E96FF1A 0% 0% no-repeat padding-box;
              border: 1px solid #1E91CF;
              border-radius: 10px;
              font-size: 12px;
              color: #1E91CF;
              padding: 10px;
              width: 135px;
            }
          }
          .drive-through{
            margin-left: 10px;
            .MuiButton-root{
              background: #2E96FF1A 0% 0% no-repeat padding-box;
              border: 1px solid #1E91CF;
              border-radius: 10px;
              font-size: 12px;
              color: #1E91CF;
              padding: 10px;
              width: 135px;
            }
          }
          .store-details-disable{
            margin-left: 50px;
            .MuiButton-root{
              background: #EAEAEA 0% 0% no-repeat padding-box;
              border-radius: 10px;
              font-size: 12px;
              color: #707070;
              padding: 10px;
              width: 135px;
            }
          }
          .store-products-disable{
            margin-left: 10px;
            .MuiButton-root{
              background: #EAEAEA 0% 0% no-repeat padding-box;
              border-radius: 10px;
              font-size: 12px;
              color: #707070;
              padding: 10px;
              width: 135px;
            }
          }
          .store-delivery-disable{
            margin-left: 10px;
            .MuiButton-root{
              background: #EAEAEA 0% 0% no-repeat padding-box;
              border-radius: 10px;
              font-size: 12px;
              color: #707070;
              padding: 10px;
              width: 135px;
            }
          }
          .drive-through-disable{
            margin-left: 10px;
            .MuiButton-root{
              background: #EAEAEA 0% 0% no-repeat padding-box;
              border-radius: 10px;
              font-size: 12px;
              color: #707070;
              padding: 10px;
              width: 135px;
            }
          }
        }
      }
  }

  .with-MarginRight-190px {
    display: flex;
    margin-left: 210px;

    @include for-max-phone-layout {
      margin-left: 0px;
    }
  }

  .with-padding-20px {
    // padding: 20px 40px 20px 20px;
    padding: 0px 20px 0px 0px;
    @include for-max-phone-layout {
      padding: 20px 15px;
    }
  }
}

.hamburger-icon {
  font-size: 21.5px;
  color: #174b9d;
}

div.MuiDrawer-paperAnchorLeft {
  display: flex;
  width: 50%;
}