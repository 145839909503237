@import "@assets/styles/variable.scss";
@import "@assets/styles/mixins.scss";

.cancel-container_paper-address{
  background: white;
  margin-top: 200px;
  margin-left: 40%;
  max-width: 450px;
  padding: 5px 30px 30px 30px;
  .cancel-container_paper-row{
    display: flex;
    .cancel-container_paper{
      margin-top: 10px;
    }
    .cancel-input-box{
      margin: 2% 0 0 5%;
      width:70%;
    }
    .reason-box{
      margin: 1% 0 0 7%;
      width:15%;
    }
    .cancel-amount-box{
      margin: 2% 0 0 3%;
      width:70%;
    }
    .cancel-reason-box{
      margin: 2% 0 0 4%;
      width:70%;
    }
    .cancel-redmine-box{
      margin: 2% 0 0 0;
      width:100%;
    }
  }
  
  .image-icon{
    margin: 3% 0 0 0;
    display: flex;
    .cancel-button{
      margin-left: 1%;
    }
  }
}

.store-details-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  font-size: 20px;
  overflow-x: auto;
  
  .store-details-container_paper {
    padding: 5px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 15.5px;
    position: relative;
    bottom: 10px;

    .store-details-container_paper-store {
      width: 50%;
      margin-bottom: 15px;
      @include for-max-phone-layout {
        width: 100%;
      }
      .search{
        min-width: 120px;
      }
      .title-input {
        margin-top: 15px;
        .title-input-box {
          width: 100%;
        }
      }
    }

    .store-details-container_paper-title {
      width: 50%;
      margin-bottom: 10px;
      font-size: 12pt;
      font-weight: bold;
      @include for-max-phone-layout {
        width: 100%;
      }
    }

    .store-details-container_paper-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      @include for-max-phone-layout {
        width: 100%;
      }
      .store-details-container_paper-row-col {
        width: 50%;
        .title-input {
          margin-top: 5px;
          .title-input-box {
            width: 100%;
          }
        }
      }
    }

    .ecommerce-details-container_paper-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      @include for-max-phone-layout {
        width: 100%;
      }
      .ecommerce-details-container_paper-row-col {
        margin-top: 10px;
        width: 50%;
        .title-input {
          margin-top: 5px;
          .title-input-box {
            width: 100%;
          }
        }
      }
    }

    .store-details-container_paper-address {
      width: 50%;
      @include for-max-phone-layout {
        width: 100%;
      }
      .address-input {
        margin-top: 5px;
        .address-input-box {
          width: 100%;
        }
      }
    }

    .store-details-container_paper-desc {
      width: 50%;
      margin-top: 15px;
      @include for-max-phone-layout {
        width: 100%;
      }
      .desc-input {
        margin-top: 15px;
        .desc-input-box {
          width: 100%;
        }
      }
    }

    .store-details-container_paper-pickup {
      width: 50%;
      margin-top: 15px;
      @include for-max-phone-layout {
        width: 100%;
      }
    }

    .store-details-container_paper-delivery {
      width: 50%;
      margin: 15px 0px;
      @include for-max-phone-layout {
        width: 100%;
      }
    }

    .store-details-container_product {
      margin-top: 20px;
      padding: 10px 20px;

      .store-details-container_product-title {
        margin-bottom: 10px;
        font-size: 12pt;
        font-weight: bold;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        @include for-max-phone-layout {
          width: 100%;
        }

        .store-details-container_product-title-add {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 10px;
          &:hover {
            background-color: #f4f4f4;
          }
          .product-title-add_button {
            color: #000;
            padding-left: 7.5px;
            font-size: 18.5px;
          }
        }
      }
    }
  }
}

.store-details-operatingHours {
  display: flex;
  flex-direction: column;
  width: 100%;
  .store-details-operatingHours_timeInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    .store-details-operatingHours_timeInput-day {
      font-weight: 600;
    }
    .store-details-operatingHours_timeInput-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      .store-details-operatingHours_timeInput-box-input {
        padding: 0px 50px;
      }
    }
    // .store-details-operatingHours_timeInput-box-select {
    //   margin-left: 50px;
    //   display: flex;
    //   width: 125px;
    // }
  }
}

.store_productList-container {
  display: flex;
  cursor: pointer;
  margin: 0px 20px 20px 0px;
  border: 1px solid #F1F7FF;
  border-radius: 6px;
  padding: 10px;
  flex-direction: column;

  &:hover {
    border: 1px solid #174b9d;
  }
  .store_productList-container_image {
    height: 70px;
    object-fit: contain;
  }
  .store_productList-container_title {
    margin-top: 5px;
    font-size: 11pt;
    font-weight: 700;
    color: #174b9d;
  }
}

.store-details_btn{
  cursor: pointer;
}

.order-payment-logs-container{
  @include tableColumnHeadWidthByIndex(1, 0px) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(2, 0px) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(3, 60px) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(4, 120px) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(5, 0px) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(6, 20px) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(7, none) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(8, none) { pointer-events: none; }
  @for $i from 1 through 6 {
    @include tableColumnBodyWidthByIndex($i, 0px, none, 0px);
  }
}

.order-refund-logs-container{
  @include tableColumnHeadWidthByIndex(1, none) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(2, none) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(3, none) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(4, none) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(5, none) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(6, none) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(7, none) { pointer-events: none; }
}

.lalamove-grab-logs-container, .Panda-logs-container{
  .title{
    padding: 0px 0px 10px 10px;
    font-family: dinalternate;
  }
}

.lalamove-logs-container{
  .customtable-container_search{
    display: none;
  }

  @include tableColumnHeadWidthByIndex(1, 10px) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(2, 100px) { pointer-events: none; }
  @include tableColumnHeadWidthByIndex(3, 110px) { pointer-events: none; width: 30px; }
  @include tableColumnHeadWidthByIndex(4, 30px) { pointer-events: none; width: 10px; }
  @include tableColumnHeadWidthByIndex(5, 300px) { pointer-events: none; width: 100px; }
  
  @include tableColumnBodyWidthByIndex(1, 0px, none, 10px);
  @include tableColumnBodyWidthByIndex(2, 0px, none, 10px);
  @include tableColumnBodyWidthByIndex(3, 0px, none, 30px);
  @include tableColumnBodyWidthByIndex(4, 0px, none, 30px);
  @include tableColumnBodyWidthByIndex(5, 0px, none, 40px);
}

.grab-logs-container, .Panda-logs-container{
  padding-top: 10px;
  .customtable-container_search{
    display: none;
  }

  // @include tableColumnHeadWidthByIndex(1, 0px) {
  //   pointer-events: none;
  //   width: 10px;
  // }
  // @for $i from 2 through 5 {
  //   @include tableColumnHeadWidthByIndex($i, none) { pointer-events: none; }
  // }

  // @for $i from 1 through 5 {
  //   @include tableColumnBodyWidthByIndex($i, none, none, none);
  // }
}

.order_delivery_order-logs-container{

  @include tableColumnHeadWidthByIndex(1, 70px) { pointer-events: none; width: 10px; }

  @include tableColumnHeadWidthByIndex(2, 120px) { pointer-events: none; width: 10px }

  @include tableColumnHeadWidthByIndex(3, 250px) { pointer-events: none; width: 150px; }

  @include tableColumnHeadWidthByIndex(4, 150px) { pointer-events: none; width: 150px; }

  @include tableColumnHeadWidthByIndex(5, 500px) { pointer-events: none; }
  
  @for $i from 1 through 5 {
    @include tableColumnBodyWidthByIndex($i, 0px, none, 0px);
  }
}
