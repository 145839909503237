
.DateRangePickerInput{
    background-color:#F5F6F9 ;
    border:none;
    position: relative;
    top: 14px;
}

.DateInput{
    visibility: hidden;
}

.DateInput_input{
    visibility: visible;
    display: flex;
    font-size: 14px;
    font-family: "din";
    border: 1px solid #EAEAEA;
    border-radius: 24px;
    padding: 5px 5px 5px 10px;
    background-color: white;
}

.DateRangePickerInput_arrow_svg{
    visibility: hidden;
}

.DateRangePickerInput_calendarIcon_svg{
    visibility: visible;
    left:10px;
}

.DateRangePicker_picker{
    z-index: 10;
}

.CalendarMonth{
    visibility: visible;
    
}

.dateIconLeft{
    position: relative;
    top:20px;
    right:30px;
}

.dateIconRight{
    position: relative;
    top:21px;
    right:205px;
}


