@mixin tableColumnHeadWidthByIndex($columnIndex, $width) {
  & th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft:nth-child(#{$columnIndex}){
    @if $width != none {
      .MuiButtonBase-root.MuiTableSortLabel-root{
        width: $width;
      }
    }
    @content;
  }
}

@mixin tableColumnBodyWidthByIndex($columnIndex, $paddingRight, $paddingLeft, $width) {
  & td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft:nth-child(#{$columnIndex}){
    @if $paddingRight != none {
      padding-right: $paddingRight;
    }
    @if $paddingLeft != none {
      padding-left: $paddingLeft;
    }
    @if $width != none {
      width: $width;
    }

    @content;
  }
}


@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-landscape {
  @media (max-width: 900px) {
    @content;
  }
}
@mixin for-desktop {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin for-medium-desktop {
  @media (max-width: 1600px) {
    @content;
  }
}
@mixin for-big-desktop {
  @media (max-width: 1800px) {
    @content;
  }
}
@mixin for-min-phone-layout {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-max-phone-layout {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin for-smallest-layout {
  @media (max-width: 337px) {
    @content;
  }
}
@mixin for-medium-desktop-layout {
  @media (max-width: 799px) {
    @content;
  }
}
