@import "@assets/styles/variable.scss";
@import "@assets/styles/mixins.scss";

.custom-section-container_paper-store {
  width: 50%;
  margin-bottom: 15px;
  font-size: 12px;

  @include for-max-phone-layout {
    width: 100%;
  }

  .title {
    white-space: nowrap;
  }
  
  .search {
    min-width: 120px;
  }
  .title-input {
    margin-top: 5px;
    
    .MuiInputBase-input.Mui-disabled {
      color: rgba(0, 0, 0, 0.87);
      font-size: 12px;
    }
    .title-input-box {
      width: 100%;
    }
    .address-input-box{
      width: 100%;
    }
  }
}

.custom-section-container_paper-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  @include for-max-phone-layout {
    width: 100%;
  }
  .custom-section-container_paper-row-col {
    width: 50%;
    .title-input {
      margin-top: 5px;
      .title-input-box {
        width: 100%;
      }
    }
  }
}

.store-details-container {
  h4 {
    margin-top: 0;
    padding-top: 0;
  }
}
