@import "@assets/styles/variable.scss";
@import "@assets/styles/mixins.scss";

.popup-confirmation {
  .MuiDialog-paperWidthSm {
    max-width: 350px;
  }
  .MuiPaper-root {
    background-color: #F1F7FF;
  }
  .MuiDialogActions-root {
    .MuiButton-textPrimary {
      color: #174b9d;
    }
    .MuiButton-textSecondary {
      color: #edaa34;
    }
  }
}

.popup-scrollable {
  .MuiDialog-paperWidthSm {
    width: 95%;
    max-width: none;
    @include for-max-phone-layout {
      width: 100%;
    }
  }
  .MuiDialog-paper {
    @include for-max-phone-layout {
      margin: 10px;
    }
  }
  .MuiDialogActions-root {
    .MuiButton-textPrimary {
      color: #174b9d;
    }
    .MuiButton-textSecondary {
      color: #edaa34;
    }
  }
}