.navigationmenu {
  width: 100%;
  position: absolute;
  z-index: 100;
  flex-direction: column;
  background-color: #1e91cf;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  img {
    padding: 30px 10px 0px 10px;
    width: 160px;
    height: 30px;
  }

  p {
    margin: 0px;
    font-size: 6.5pt;
    color: white;
    font-weight: 500;
    text-align: right;
    letter-spacing: 1px;
    text-shadow: 1px 1px #ddd;
    padding: 0px 20px 10px 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 0px 10px 20px;
    color: white;

    &--selected {
      background-color: #fff;
      border-left: solid 4px rgb(237, 170, 52);
      color: #1e91cf;
    }

    span {
      font-size: 11pt;
      margin-left: 15px;
      font-weight: 500;
    }
    svg {
      font-size: 24px;
      color: #174b9d;
    }
  }
}
