.button-container {
  margin: 0px;
  display: inline;
  position: relative;
  a{
    text-decoration: none;
  }
  .primary-btn {
    color: #fff;
    font-family: din;
    font-size: 12.5px;
    background: #1E91CF 0% 0% no-repeat padding-box;
    padding: 6.5px 20px;
    border-radius: 23px;
    width: 60px;
    height:32px;
    line-height: initial;
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }

  .user-account-primary-btn {
    color: #fff;
    font-family: din;
    font-size: 12.5px;
    background: #1E91CF 0% 0% no-repeat padding-box;
    padding: 20px 20px;
    border-radius: 45px;
    width: 190px;
    height:55px;
    margin:0px 10px 20px 0px;
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }

  .proceed-btn{
    color: #fff;
    font-family: din;
    font-size: 10px;
    background: #1E91CF 0% 0% no-repeat padding-box;
    padding: 6.5px 20px;
    border-radius: 23px;
    width: 75px;
    height:18px;
    line-height: initial;
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }
  .proceed-btn:disabled { 
    color: black;
  }
  .button-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
  }
  .button-panel-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -55px;
    margin-left: -10px;
  }
  
  .secondary-btn {
    color: #09A74C;
    font-family: din;
    width:60px;
    height:32px;
    background-color: #fff;
    padding: 5px 20px;
    font-size: 12.5px;
    border: 2px solid #09A74C;
    border-radius: 23px;
    line-height: initial;
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }

  .driveThrough-secondary-btn {
    color: #1E91CF ;
    font-family: din;
    width:135px;
    height:35px;
    background-color: #fff;
    padding: 5px 15px;
    font-size: 12.5px;
    border: 2px solid #1E91CF;
    border-radius: 23px;
    line-height: initial;
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }
  .warn-btn {
    color: #fff;
    font-family: din;
    width: 140px;
    height:32px;
    background-color: #F0455B;
    padding: 5px 20px;
    font-size: 12.5px;
    border-radius: 50px;

    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }
  .delete-btn {
    color: #fff;
    font-family: din;
    width: 75px;
    height:32px;
    background-color: #F0455B;
    padding: 5px 20px;
    font-size: 12.5px;
    border-radius: 50px;

    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }

  .disable-btn {
    color: #fff;
    font-family: din;
    width: 170px;
    height:32px;
    background-color: #F0455B;
    padding: 5px 20px;
    font-size: 12.5px;
    border-radius: 50px;
  

    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }
  .disable-fade-btn {
    color: #fff;
    font-family: din;
    width: 170px;
    height:32px;
    background-color: #C0C0C0;
    padding: 5px 20px;
    font-size: 12.5px;
    border-radius: 50px;
  

    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }
  .enable-btn {
    color: #fff;
    font-family: din;
    width: 170px;
    height:32px;
    background-color: #09A74C;
    padding: 5px 20px;
    font-size: 12.5px;
    border-radius: 50px;
  

    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }
  .cancel-btn {
    color: #F0455B;
    font-family: din;
    width: 75px;
    height:32px;
    background-color: #fff;
    padding: 5px 20px;
    font-size: 12.5px;
    border: 2px solid #F0455B;
    border-radius: 23px;

    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
  }
  
  .download-btn {
    color: #fff;
    font-family: din;
    width: 90px;
    height:32px;
    background: #1E91CF;
    font-size: 12.5px;
    border-radius: 23px;
    
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
    
    .MuiCircularProgress-svg{
      color: #fff
    }
  }

  .download-icon-btn {
    color: #1E91CF;
    width: 190px;
    height:35px;
    background: transparent;
    border-radius: 23px;
    border: 2px solid #1E91CF;
    padding-left: 16px;
    padding-right: 16px;
    
    &:hover {
      border: none;
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
    
    .MuiCircularProgress-svg{
      color: #fff
    }
  }
  
  .import-btn {
    color: #fff;
    width: 310px;
    height:35px;
    background: #1E91CF 0% 0% no-repeat padding-box;
    border-radius: 23px;
    padding-left: 16px;
    padding-right: 16px;
    
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
    
    .MuiCircularProgress-svg{
      color: #fff
    }
  }

  .spinner{
    position: relative;
    top: 6px;
  }

  .enableWEB-btn {
    color: #fff;
    font-family: din;
    width: 140px;
    height:40px;
    background: #1E91CF;
    font-size: 12.5px;
    border-radius: 23px;
    
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
    
    .MuiCircularProgress-svg{
      color: #fff
    }
  }

  .resetWEB-btn {
    color: #fff;
    font-family: din;
    width: 95px;
    height:40px;
    background: #1E91CF;
    font-size: 12.5px;
    border-radius: 23px;
    
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
    
    .MuiCircularProgress-svg{
      color: #fff
    }
  }

  .resetKDS-btn {
    color: #fff;
    font-family: din;
    width: 110px;
    height:40px;
    background: #1E91CF;
    font-size: 12.5px;
    border-radius: 23px;
    
    &:hover {
      background-color: rgb(237, 170, 52);
      color: #fff;
      opacity: 0.8;
    }
    
    .MuiCircularProgress-svg{
      color: #fff
    }
  }
  
  .spinner{
    position: relative;
    top: 6px;
  }
}
